import NormalPhoneButton from "../sideBarPhoneButtons/NormalPhoneButton";
import CollapsePhoneButton from "../sideBarPhoneButtons/CollapsePhoneButton";

const YmsPortalSideBar = (props) => {
  const { sideBarState, handleCloseSideBar, handleOpenSideBar } = props;
  return (
    <>
      {" "}
      <NormalPhoneButton
        key={"home"}
        ButtonProperties={{
          url: "/HomeView",
          iconName: "Home",
          titleText: "Home",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalPhoneButton
        key={"inoutgate"}
        ButtonProperties={{
          url: "/yms-portal/checkinout",
          iconName: "In-Out Gate",
          titleText: "In/Out Gate",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalPhoneButton
        key={"pending"}
        ButtonProperties={{
          url: "/yms-portal/pending",
          iconName: "Pending",
          titleText: "Pending",
          functionDefault: handleCloseSideBar,
        }}
      />{" "}
      <NormalPhoneButton
        key={"gatelog"}
        ButtonProperties={{
          url: "/yms-portal/gatelog",
          iconName: "Gate Log",
          titleText: "Gate Log",
          functionDefault: handleCloseSideBar,
        }}
      />
      <NormalPhoneButton
        key={"inyardstorage"}
        ButtonProperties={{
          url: "/yms-portal/inyardstorage",
          iconName: "Yard Storage",
          titleText: "Yard Storage",
          functionDefault: handleCloseSideBar,
        }}
      />
      <CollapsePhoneButton
        ButtonProperties={{
          iconName: "Flip",
          sideBarState: sideBarState,
          setDefaultFunction: handleCloseSideBar,
          sideBarFunction: handleOpenSideBar,
          titleText: "Yard Tasks",
          childrenButtons: [
            {
              id: "ymsFlips",
              url: "/yms-st/pending-flips",
              titleText: "Pending Flips",
            },
            {
              id: "pendingInspect",
              url: "/yms-st/pending-inspect",
              titleText: "Pending Inspect",
            },
          ],
        }}
      />
      <CollapsePhoneButton
        ButtonProperties={{
          iconName: "Street Turn",
          sideBarState: sideBarState,
          setDefaultFunction: handleCloseSideBar,
          sideBarFunction: handleOpenSideBar,
          titleText: "Empties",
          childrenButtons: [
            {
              id: "streetTurnSummary",
              url: "/yms-st/streetturn-sslsummary",
              titleText: "SSL Summary",
            },
            {
              id: "streetTurnPending",
              url: "/yms-st/streetturn-sslsummarydetails/?sslScac=ALL",
              titleText: "Available Empties",
            },
            {
              id: "stPEnding",
              url: "/yms-st/sreetturnspending",
              titleText: "Empties For Export",
            },
          ],
        }}
      />{" "}
      <CollapsePhoneButton
        ButtonProperties={{
          iconName: "cont. History",
          sideBarState: sideBarState,
          setDefaultFunction: handleCloseSideBar,
          sideBarFunction: handleOpenSideBar,
          titleText: "History",
          childrenButtons: [
            {
              id: "conHistory",
              url: "/yms-portal/containershistory",
              titleText: "Cont. History",
            },
            {
              id: "chassisHistory",
              url: "/yms-chassis-portal/chassis-history",
              titleText: "Chassis History",
            },
          ],
        }}
      />
      <NormalPhoneButton
        key={"drivers"}
        ButtonProperties={{
          url: "/yms-portal/drivers",
          iconName: "Drivers",
          titleText: "Drivers",
          functionDefault: handleCloseSideBar,
        }}
      />
    </>
  );
};
export default YmsPortalSideBar;
